import React from "react"
import styles from "./projeler.module.scss"
import Menubar from "../components/menubar/menubarEng"
import ProjectFoursome from "../components/productFoursome/productFoursome"
import Footer from "../components/footer/footerEng"
import { useStaticQuery, graphql } from "gatsby"
import ProjectTopImage from "../img/proje_975.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      project1: file(relativePath: { eq: "nyKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project2: file(relativePath: { eq: "kirklareliKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project3: file(relativePath: { eq: "onVillaKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project4: file(relativePath: { eq: "syKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project5: file(relativePath: { eq: "yesilyurtOfisKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project6: file(relativePath: { eq: "mbKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project7: file(relativePath: { eq: "myKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project8: file(relativePath: { eq: "yesilyurtDCKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project9: file(relativePath: { eq: "taiTusasKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project10: file(relativePath: { eq: "demayofisKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project11: file(relativePath: { eq: "demirkilicKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project12: file(relativePath: { eq: "oncuogluKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  return (
    <div className={styles.projelerContainer}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Menubar />
        <div className={styles.imageTopProjelerContainer}>
          <img
            src={ProjectTopImage}
            alt="projects"
            className={styles.imageTopProjeler}
          />
          <div className={styles.verticalLineWhiteProjeler}>
            <div className={styles.socialMediaIcons}>
              <a
                href="https://www.facebook.com/atiaahsap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="1x"
                  className={styles.ffIcon}
                />
              </a>
              <br />
              <a
                href="https://www.instagram.com/atiaahsap/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="1x"
                  className={styles.igIcon}
                />
              </a>
            </div>
          </div>
        </div>
        <ProjectFoursome
          /* Product 1-1 */
          iSrc1={data.project1.childImageSharp.fluid.src}
          info1={`NY \nHOUSING`}
          iLink1={`/projects/nyKonutuEng`}
          /* Product 1-2 */
          iSrc2={data.project2.childImageSharp.fluid.src}
          info2={`KIRKLARELI GOVERNORSHIP \nASSEMBLY HALL`}
          iLink2={`/projects/kirklareliValilikEng`}
          /* Product 1-3 */
          iSrc3={data.project3.childImageSharp.fluid.src}
          info3={`DATÇA - BURGAZ \nVILLAS`}
          iLink3={`/projects/datcaBurgazVillaEng`}
          /* Product 1-4 */
          iSrc4={data.project4.childImageSharp.fluid.src}
          info4={`SY \nHOUSING`}
          iLink4={`/projects/syKonutuEng`}
        />
        <ProjectFoursome
          /* Product 2-1 */
          iSrc1={data.project5.childImageSharp.fluid.src}
          info1={`YEŞİLYURT ENERGY \nOFFICE`}
          iLink1={`/projects/yesilyurtOfisEng`}
          /* Product 2-2 */
          iSrc2={data.project6.childImageSharp.fluid.src}
          info2={`MB \nHOUSING`}
          iLink2={`/projects/mbKonutuEng`}
          /* Product 2-3 */
          iSrc3={data.project7.childImageSharp.fluid.src}
          info3={`MY \nHOUSING`}
          iLink3={`/projects/myKonutuEng`}
          /* Product 2-4 */
          iSrc4={data.project8.childImageSharp.fluid.src}
          info4={`YESILYURT IRON & STEEL \nADMINISTRATION BUILDING`}
          iLink4={`/projects/yesilyurtDCEng`}
        />
        <ProjectFoursome
          /* Product 3-1 */
          iSrc1={data.project9.childImageSharp.fluid.src}
          info1={`TAI & TUSAS \nHR BUILDING`}
          iLink1={`/projects/taiTusasEng`}
          /* Product 3-2 */
          iSrc2={data.project10.childImageSharp.fluid.src}
          info2={`D'ARCHITECTs DEMAY \nARCHITECTURE OFFICE`}
          iLink2={`/projects/demayOfisEng`}
          /* Product 3-3 */
          iSrc3={data.project11.childImageSharp.fluid.src}
          info3={`DEMIRKILIC \nHOUSING`}
          iLink3={`/projects/demirkilicKonutuEng`}
          /* Product 3-4 */
          iSrc4={data.project12.childImageSharp.fluid.src}
          info4={`ONCUOGLU \nARCHITECTURE OFFICE`}
          iLink4={`/projects/oncuogluOfisEng`}
        />
        <Footer />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}
export default Projects
