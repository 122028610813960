import React from "react"
import styles from "./footer.module.scss"
import LogoWhite from "../../img/atia-logo-white.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faMapMarker } from "@fortawesome/free-solid-svg-icons"
import { faAddressCard } from "@fortawesome/free-regular-svg-icons"

export default props => (
  <div
    className={styles.footerContainer}
    style={{ display: `${props.displayVal}` }}
  >
    <div className={styles.middleArea}>
      <div className={styles.e1}></div>
      <div className={styles.f1}>
        <img src={LogoWhite} alt="logo-white" className={styles.logo} />
      </div>
      <div className={styles.f2}>
        <div className={styles.footerTextDetails}>
          <FontAwesomeIcon icon={faPhone} className={styles.iconsFooter} />
          <p className={styles.textsFooter}>
            T: +90 312 348 6307 <br /> F: +90 312 350 2187
          </p>
        </div>
      </div>
      <div className={styles.f3}>
        <div className={styles.footerTextDetails}>
          <FontAwesomeIcon
            icon={faAddressCard}
            className={styles.iconsFooter}
            style={{ marginTop: "5px" }}
          />
          <p className={styles.textsFooter}>
            <a
              href="mailto:atia@atia.com.tr"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#ffffff" }}
            >
              atia@atia.com.tr
            </a>
          </p>
        </div>
      </div>
      <div className={styles.f4}>
        <div className={styles.footerTextDetails}>
          <FontAwesomeIcon icon={faMapMarker} className={styles.iconsFooter} />
          <p className={styles.textsFooter}>
            Ereğli Ave. No: 65/3-4
            <br />
            Siteler/Ankara
            <br />
            <br />
          </p>
        </div>
      </div>
      <div className={styles.e2}></div>
    </div>
    <div className={styles.copyRightArea}>
      Copyright © 2020 Atia, All Rights Reserved
    </div>
  </div>
)
