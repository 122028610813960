import React from "react"
import styles from "./productSingle.module.scss"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons"

export default props => (
  <div className={styles.productSingleContainer}>
    <Link to={props.iLink}>
      <div
        style={{ backgroundImage: `url(${props.iSrc})` }}
        className={styles.productBG}
      >
        <div
          className={styles.productDetailShow}
          state={{
            pname: props.info,
          }}
        >
          <FontAwesomeIcon
            icon={faSearchPlus}
            size="2x"
            className={styles.fontAwSearch}
          />
        </div>
        <div className={styles.infoText}>{props.info}</div>
      </div>
    </Link>
  </div>
)
