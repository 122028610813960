import React from "react"
import styles from "./productFoursome.module.scss"
import ProductSingle from "../productSingle/productSingle"

export default props => (
  <div
    className={styles.productFoursomeContainer}
    style={{ display: `${props.displayProp}` }}
  >
    <ProductSingle
      className={styles.pr1}
      iSrc={props.iSrc1}
      info={props.info1}
      iLink={props.iLink1}
    />
    <ProductSingle
      className={styles.pr2}
      iSrc={props.iSrc2}
      info={props.info2}
      iLink={props.iLink2}
    />
    <ProductSingle
      className={styles.pr3}
      iSrc={props.iSrc3}
      info={props.info3}
      iLink={props.iLink3}
    />
    <ProductSingle
      className={styles.pr4}
      iSrc={props.iSrc4}
      info={props.info4}
      iLink={props.iLink4}
    />
  </div>
)
